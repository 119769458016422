ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #f5f5f5;
    z-index: 10;
}

li {
    float: right;
    font-size: 1.4em;
}

.desktop li {
    display: block;
    padding: 15px 30px;
    color: black;
}

a {
    text-decoration: none;
}

.navLogo {
    width: 1.9em;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.active {
    display: inline-block;
}

.navbarLink {
    color: black;
}

.mobile {
    display: none;
}

.navLeft {
    float: left;
}
.menu {
    display: none;
}

@media screen and (max-width: 575px) {
    .mobile {
        display: inline-block;
    }

    .desktop {
        display: none;
    }

    .mobile {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        position: fixed;
        top: 0;
        width: 100%;
        background-color: #f5f5f5;
        z-index: 100;
        
    }
    .menu {
        display: block;
        width: 100%;
        height: 100vh;
        position: fixed;
        background-color: #f5f5f5;
        z-index: 99;
        overflow:hidden;
        padding-top: calc(40px + 1.9em);
        transition: transform 0.6s ease-in-out; /* Adding a transition */
    }
    .navMob {
        margin: 20px;
    }
    
    .navElement {
        margin: 20px;
    }
    .menuOpen {
        transform: translateX(0);
    }
    .menuClosed {
        transform: translateX(-100%);
    }
}