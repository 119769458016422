.homeTitle {
    font-size: 3em;
    margin: 100px 0px 25px 100px;
}

.homeSubtitle {
    font-size: 2em;
    margin: 0px 0px 25px 100px;
}

.homeImage {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.info {
    font-size: 2em;
    text-align: center;
}

.create {
    font-size: 2em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 100px;
    margin-bottom: 50px;
    border: 2px white solid;
    display: inline-block;
    padding: 10px 15px;
    color: white;
    border-radius: 50px;
    transition: 1s;
    background-color: rgba(255, 255, 255, 0.05);
}

.create:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.about {
    text-align: center;
}

.app {
    border: 1px solid #333; /* Border color */
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out;
    background-color: #313131;
}

.appTitle {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 10px;
}

.bitcoin {
    width: 250px;
    display: block;
    margin: auto;
}
.appButton {
    color: white;
    border-radius: 25px;
    background-color: #ffbd43;
    border: none;
    padding: 12px;
    cursor: pointer;
    margin-top: 10px;
    display: block;
    width:fit-content;
}
.appDescription {
    font-size: 1.2em;
    margin: 0;
}
.app1 {
    width: 50%;
}
.roadmap {
    color: white;
    background-color: rgb(18, 14, 56);
    display: flex;
    padding: 50px;
    margin: 0;
    height: fit-content;
  }
  
  .timeline {
    position: relative;
    margin: 50px 0 0px 100px;
  }
  
  .timeline::after {
    content: '';
    position: absolute;
    width: 4px;
    background-color: #4285f4;
    top: 0;
    bottom: 0;
    left: 75%;
    margin-left: -2px;
  }
  
  .timeline-item {
    position: relative;
    margin-bottom: 50px;
    color: black;
  }
  
  .timeline-item::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #4285f4;
    border-radius: 50%;
    top: 0;
    left: 75%;
    transform: translate(-50%, -50%);
  }
  
  .timeline-content {
    position: relative;
    width: 80%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-left: -5%;
  }
  
  .timeline-content h2 {
    font-size: 18px;
    margin-top: 0;
  }
  
  .timeline-content p {
    margin-bottom: 0;
  }
  .timeline {
   width: 50%;
  display: inline;
  margin-left: 10%;
  }
  .roadmaptext {
    width: 50%;
  }
  .roadmapsheading {
    opacity: 70%;
    color:#001eff;
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 5px;
  }
  .roadmapheading {
    font-size: 2.5em;
    margin-top: 0;
  }
  @media (max-width: 800px) {
  .timeline {
    display: block;
    width: 100%;
  }
  
  .roadmaptext {
    width: 100%;
    display: block;
  }
  .roadmap {
    display: block;
  }
  .astronaut {
    width: 100%;
  }}

  .half {
    width: 50%;
    display: inline-block;
    height: fit-content;
  }
  
@media screen and (max-width: 660px) {
    .app1 {
        width: 100%;
    }
    .homeTitle, .homeSubtitle, .create {
        margin-left: 25px;
        margin-right: 25px;
    }
    .create {
        margin-bottom: 50px;
    }
    .half {
        width: 100%;
    }
    .bitcoin {
        display: none;
    }
}