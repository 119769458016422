.token {
    border: 1px solid #333; /* Border color */
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    margin-left: 50px;
    margin-right: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out;
    background-color: #313131; /* Token background color */
    overflow-wrap: break-word;
  }
  
  .token:hover {
    transform: translateY(-3px);
  }
  
  .tokenName {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 5px;
    color: #fff; /* Token name color */
  }
  
  .info {
    margin-bottom: 8px;
    color: #ccc; /* Info text color */
  }
  
  /* Optional: Add different styles based on the type of token */
  /* Example: If the token symbol is BTC, apply different styles */
  .tokenName:contains('(BTC)') {
    color: #f7931a; /* Bitcoin color */
    /* You can add more specific styles for Bitcoin here */
  }
.myTokensHeading {
    padding: 100px 0 50px 100px;
    margin-bottom: 0px;
}
.heading {
    font-size: 3em;
    margin: 0;
}
.subheading {
    font-size: 1.5em;
    margin: 0;
}
.myTokensError {
  margin-left: 100px;
}